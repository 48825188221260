(function () {
	'use strict';
	angular
		.module('minicals')
		.controller('MinicalsCtrl', [
			'$scope',
			'seedcodeCalendar',
			'utilities',
			'fullCalendarBridge',
			'filemakerJS',
			MinicalsCtrl,
		]);

	function MinicalsCtrl(
		$scope,
		seedcodeCalendar,
		utilities,
		fullCalendarBridge,
		filemakerJS
	) {
		$scope.calendar = {};
		$scope.calendar.date = {};

		// Get config
		$scope.calendar.config = seedcodeCalendar.get('config');

		// Initialize date object values
		const date = seedcodeCalendar.get('date');
		if (date) {
			$scope.calendar.date.selected = date.selected;
			$scope.calendar.date.nav = date.nav;
			$scope.calendar.date.preventUpdate = date.preventUpdate;
		}

		// Watch for date object broadcasts
		$scope.$on('date', function (e, dateChanges) {
			$scope.calendar.date.selected = dateChanges.selected;
			$scope.calendar.date.nav = dateChanges.nav;
			$scope.calendar.date.preventUpdate = dateChanges.preventUpdate;
		});

		$scope.close = function (e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		};

		$scope.navigateMiniCals = function (direction, scale) {
			// $scope.calendar.noWatcher = true;
			if (!scale) {
				scale = 'month';
			}

			if (direction === 'next') {
				$scope.calendar.date.nav = moment($scope.calendar.date.nav).add(
					1,
					scale
				);
			} else if (direction === 'previous') {
				$scope.calendar.date.nav = moment(
					$scope.calendar.date.nav
				).subtract(1, scale);
			}

			// Set scope var to activate navigation. (date pickers should be watching for this value)
			$scope.activateNav = new Date().valueOf();
		};

		//Watcher is here for selections made in the datepicker
		$scope.$watch('calendar.date.selected', function () {
			if ($scope.calendar.date) {
				if ($scope.calendar.date.preventUpdate) {
					$scope.calendar.date.preventUpdate = false;
				} else {
					fullCalendarBridge.gotoDate(
						seedcodeCalendar.get('element'),
						$scope.calendar.date.selected
					);
					//if we're in FileMakerJS update our values
					if (
						utilities.getDBKPlatform() &&
						(utilities.getDBKPlatform() === 'dbkfmjs' ||
							utilities.getDBKPlatform() === 'dbkfmwd')
					) {
						filemakerJS.loadFileMakerJSConfig();
					}
				}
			}
		});
	}
})();
